<template>
  <div class="doctor_detail_container">
    <div class="breadcrumb_wrap">
      <div class="m_1200">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>咨询师详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="doctor_detail_inner m_1200">
      <div class="base_info">
        <img :src="newInfo.avatar" alt="" class="avatar" />
        <div class="info">
          <h3>{{ newInfo.name }}</h3>
          <div class="item">
            <img
              src="../assets/images/doctor_detail4.png"
              alt=""
              class="icon"
            />
            <div class="label">咨询经验</div>
            <p class="value">{{ newInfo.experience }}</p>
            <img src="../assets/images/doctor_detail3.png" alt="" />
          </div>
          <div class="item">
            <img
              src="../assets/images/doctor_detail5.png"
              alt=""
              class="icon"
            />
            <div class="label">咨询方式</div>
            <p class="value">{{ newInfo.mode }}</p>
          </div>
          <div class="item">
            <img
              src="../assets/images/doctor_detail6.png"
              alt=""
              class="icon"
            />
            <div class="label">咨询费用</div>
            <p class="value">{{ newInfo.price }}元/次</p>
          </div>
          <div class="item">
            <img
              src="../assets/images/doctor_detail7.png"
              alt=""
              class="icon"
            />
            <div class="label">城市</div>
            <p class="value">{{ newInfo.city }}<br /></p>
          </div>
        </div>
        <div class="right">
          <div class="title">写给来访者</div>
          <p class="content">{{ newInfo.visitors }}</p>
        </div>
      </div>
      <div class="btn_wrap">
        <div class="btn" @click="apply">预约咨询</div>
      </div>
      <div class="tabs_box">
        <ul class="list_box">
          <li
            class="list_item"
            @click="handleTabs(0)"
            :class="{ active: current === 0 }"
          >
            个人简介<span class="dot"></span>
          </li>
          <li
            class="list_item"
            @click="handleTabs(1)"
            :class="{ active: current === 1 }"
          >
            咨询师专栏<span class="dot"></span>
          </li>
          <li
            class="list_item"
            @click="handleTabs(2)"
            :class="{ active: current === 2 }"
          >
            咨询师相册<span class="dot"></span>
          </li>
          <li
            class="list_item"
            @click="handleTabs(3)"
            :class="{ active: current === 3 }"
          >
            预约须知<span class="dot"></span>
          </li>
          <li
            class="list_item"
            @click="handleTabs(4)"
            :class="{ active: current === 4 }"
          >
            可约时间<span class="dot"></span>
          </li>
        </ul>
        <div
          class="content"
          v-html="htmlStr"
          v-if="current == 0 || current == 1 || current == 3"
        ></div>
        <div class="content" v-if="current == 4">
          {{ newInfo.approximate_time }}
        </div>
		<div class="content" v-if="current == 2">
		  <img :src="item" v-for="(item,index) in htmlStr" :key="index" class="photoList"/>
		</div>
      </div>
    </div>
    <el-dialog title="信息提交" :visible.sync="dialogVisible" :width="device ? '90%' : '30%'" @closed="handleClose">
      <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
        <el-form-item label="姓名：" prop="name">
          <el-input v-model.trim="formData.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <el-radio-group v-model="formData.sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄：" prop="age">
          <el-input v-model.number="formData.age" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <el-form-item label="联系方式：" prop="phone">
          <el-input v-model.trim="formData.phone" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="咨询内容：" prop="content">
          <el-input type="textarea" v-model.trim="formData.content" placeholder="请输入咨询内容"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="二维码" :visible.sync="isCode" :width="device ? '90%' : '500px'">
      <div class="code_dialog">
        <img src="../assets/images/code.png" alt="" style="width: 100%;">
        <p style="text-align: center; margin-top: 12px;font-weight:bold;">请扫描客服二维码了解预约进度</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isCode = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex' 
export default {
  data () {
    var validatePhone = (rule, value, callback) => {
      if (!/^1[23456789]\d{9}$/.test(value)) {
        callback(new Error('手机格式不正确'));
      }
       callback();
    };
    return {
      formData: {
        name: '',
        sex: '',
        age: '',
        phone: '',
        content: ''
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        age: [
          { required: true, message: '请输入年龄', trigger: 'blur' },
          { type: 'number', message: '年龄必须为数字值'}
        ],
        phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        content: [{ required: true, message: '请输入咨询内容', trigger: 'blur' }],
      },
      dialogVisible: false,
      isCode: false,
      current: 0, //当前tabs下标
      htmlStr: '',
      newInfo: ''
    }
  },
  computed: {
    ...mapState(['device'])
  },
  mounted () {
    this.$http({
      method: 'POST',
      url: '/Other/ConsultantInfo',
      data: { id: this.$route.query.id }
    }).then(res => {
      this.newInfo = res.data
      this.htmlStr = res.data.introduction
    })
  },
  methods: {
    //预约表单提交
    handleClose () {
      this.formData.name = ''
      this.formData.sex = ''
      this.formData.age = ''
      this.formData.phone = ''
      this.formData.content = ''
      requestAnimationFrame(() => this.$refs['formData'].clearValidate())
    },
    // 预约咨询
    apply () {
      var userinfo = localStorage.getItem('userinfo')
      if (!userinfo) {
        this.$message.error('请先登录')
        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 1000)
        return
      }
      this.dialogVisible = true
    },
    //提交预约
    submitForm() {
      this.$refs['formData'].validate((valid) => {
        if (valid) {
          this.$http({
            method: 'POST',
            url: '/Consultant/Appointment',
            data: { 
              cid: this.$route.query.id,
              name: this.formData.name,
              sex: this.formData.sex,
              age: this.formData.age,
              phone: this.formData.phone,
              content: this.formData.content 
            }
          }).then(res => {
            this.$message.success('提交预约成功')
            this.dialogVisible = false
            this.isCode = true
          })
        } else {
          this.$message.warning('请补全必填信息')
          return false;
        }
      })
    },
    //切换tabs
    handleTabs (index) {
      this.current = index
      if (index == 0) {
        this.htmlStr = this.newInfo.introduction
      } else if (index == 1) {
        this.htmlStr = this.newInfo.special
      } else if (index == 2) {
        this.htmlStr = this.newInfo.img_list?this.newInfo.img_list.split(','):''
      } else if (index == 3) {
        this.htmlStr = this.newInfo.notice
      } else if (index == 4) {
        this.htmlStr = this.newInfo.approximate_time
      }
    }
  }
}
</script>

<style lang="scss">
.doctor_detail_container {
  padding-bottom: 42px;
  background-color: #ffffff;
  .breadcrumb_wrap {
    padding: 32px 0;
    background-color: #f5f5f5;
  }
  .doctor_detail_inner {
    padding-top: 32px;
    .base_info {
      display: flex;
      .avatar {
        width: 274px;
        height: 274px;
        margin-right: 30px;
      }
      .info {
        margin-right: 6px;
        padding-right: 80px;
        border-right: 1px solid #f2f3f7;
        h3 {
          margin-bottom: 32px;
          font-size: 24px;
          font-weight: bold;
        }
        .item {
          display: flex;
          align-items: flex-start;
          padding: 16px 0;
		  width: 400px;
          & + .item {
            border-top: 1px solid #f2f3f7;
          }
          .icon {
            margin-right: 6px;
          }
          .label {
            margin-right: 46px;
            color: #999999;
            font-weight: bold;
          }
          .value {
            margin-right: 8px;
            font-size: 16px;
			width: 260px;
			line-height:26px;
            span {
              color: #999999;
              font-size: 12px;
            }
          }
        }
      }
      .right {
        flex: 1;
        .title {
          padding-left: 26px;
          color: #333;
          font-size: 16px;
          font-weight: bold;
          height: 52px;
          line-height: 52px;
          background: url('../assets/images/doctor_detail2.png') no-repeat left
            center / 52px 52px;
        }
        .content {
          padding: 36px 0 0 26px;
          font-size: 14px;
          line-height: 1.5;
          text-align: justify;
		  
        }
      }
    }
    .btn_wrap {
      display: flex;
      margin-left: 304px;
      padding: 20px 0;
      .btn {
        padding: 15px 16px;
        font-size: 16px;
        font-weight: 400;
        background-color: #e51418;
        border-color: #e51418;
        border-radius: 3px;
        cursor: pointer;
        color: #ffffff;
      }
    }
    .tabs_box {
      display: flex;
      border-top: 1px solid #f2f3f7;
      .list_box {
        width: 274px;
        padding: 30px 0;
        border-right: 1px solid #f2f3f7;
        .list_item {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #6b707f;
          font-size: 18px;
          font-weight: bold;
          cursor: pointer;
          &.active {
            color: #e51418;
          }
          & + .list_item {
            position: relative;
            margin-top: 36px;
          }
          .dot {
            width: 8px;
            height: 8px;
            margin-left: 8px;
            margin-right: 20px;
            border-radius: 50%;
            background-color: #e51418;
          }
        }
      }
      .content {
        padding: 24px;
		.photoList{
			width: 285px;
			margin-right: 10px;
		}
		.photoList:nth-child(3n){
			margin-right: 0;
		}
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .doctor_detail_container {
    padding-bottom: 24px;
    background-color: #ffffff;
    .breadcrumb_wrap {
      padding: 15px;
      background-color: #f5f5f5;
    }
    .doctor_detail_inner {
      padding: 15px;
      .base_info {
        display: flex;
        .avatar {
          width: 80px;
          height: 80px;
          margin-right: 10px;
        }
        .info {
          flex: 0 0 calc(100% - 120px);
          margin-right: 0px;
          padding-right: 0px;
          border-right: 1px solid #f2f3f7;
          h3 {
            margin-bottom: 0px;
            font-size: 18px;
            font-weight: bold;
          }
          .item {
            display: flex;
            align-items: center;
            white-space: normal;
            padding: 8px 0;
            & + .item {
              border-top: 1px solid #f2f3f7;
            }
            .icon {
              margin-right: 6px;
            }
            .label {
              margin-right: 12px;
              color: #999999;
              font-weight: bold;
              white-space: nowrap;
            }
            .value {
              margin-right: 8px;
              font-size: 12px;
              font-weight: bold;
			  width: 50%;
              span {
                display: inline-block;
                padding-top: 5px;
                color: #999999;
                font-size: 12px;
              }
            }
          }
        }
        .right {
          flex: 0 0 calc(100% - 30px);
          .title {
            padding-left: 26px;
            color: #333;
            font-size: 16px;
            font-weight: bold;
            height: 52px;
            line-height: 52px;
            background: url('../assets/images/doctor_detail2.png') no-repeat
              left center / 52px 52px;
          }
          .content {
            padding: 12px 0 0;
            font-size: 14px;
            line-height: 1.5;
            text-align: justify;
          }
        }
      }
      .btn_wrap {
        width: calc(100% - 30px);
        display: flex;
        justify-content: center;
        margin-left: 0;
        padding: 20px 0;
        .btn {
          padding: 8px 12px;
          font-size: 12px;
          font-weight: 400;
          border: 1px solid #e51418;
          border-radius: 3px;
          cursor: pointer;
          & + .btn {
            padding-left: 70px;
            padding-right: 70px;
            margin-left: 10px;
            color: #ffffff;
            background-color: #e51418;
            border-color: #e51418;
          }
        }
      }
      .tabs_box {
        display: flex;
        border-top: 1px solid #f2f3f7;
        .list_box {
          flex: 0 0 100px;
          padding: 15px 0;
          border-right: 1px solid #f2f3f7;
          .list_item {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #6b707f;
            font-size: 12px;
            font-weight: bold;
            cursor: pointer;
            &.active {
              color: #e51418;
            }
            & + .list_item {
              position: relative;
              margin-top: 30px;
            }
            .dot {
              width: 8px;
              height: 8px;
              margin-left: 8px;
              margin-right: 12px;
              border-radius: 50%;
              background-color: #e51418;
            }
          }
        }
        .content {
          box-sizing: border-box;
          padding: 12px;
		  width: 70%;
        }
      }
    }
  }
}
</style>
